<template>
      <chart-notes v-if="patient" class="mb-4"/>
</template>

<script>
import ChartNotes from '@/components/ChartNotes'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [],
  data() {
    return {
        chartNotes: [],
        updatingNotes: false,
    }
  },
  components: {
    ChartNotes
  },
  computed: {
    ...mapState('patient', [
      'patient'
    ])
  },
  methods: {
    ...mapActions('patient', [
      'search',
      'getPatient'
    ])
  },
mounted() {
  if (!this.patient) {
      this.getPatient({ id: this.$route.params.id })
    }
  }
}
</script>

<style>

</style>